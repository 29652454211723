
import { Component, InjectReactive, Vue, Watch } from "vue-property-decorator";
import openURL from 'quasar/src/utils/open-url.js';;
import TradeUrlInput from "@/components/user/TradeUrlInput.vue";
import HomeHeading from "@/components/home/HomeHeading.vue";
import SteamItemsList from "@/components/steam/SteamItemsList.vue";
import HomeBalanceWithdraw from "@/components/home/HomeBalanceWithdraw.vue";
import * as _ from "lodash";
import SteamTradeOffer from "@/components/steam/SteamTradeOffer.vue";
import { PaymentSystem } from "@/components/home/PaymentSystem";
import CircleLoading from "@/components/loading/CircleLoading.vue";
import { gsap } from "gsap";
import number from "vue-i18n/src/components/number";
import * as SteamId from "steamid";
import { BALANCE_CURRENCY_SYMBOL } from "@/constants";

@Component({
  components: {
    TradeUrlInput,
    HomeHeading,
    SteamItemsList,
    HomeBalanceWithdraw,
    SteamTradeOffer,
    CircleLoading
  },
  meta() {
    return { title: this.$t("partner.title") };
  }
})
export default class Partner extends Vue {
  @InjectReactive(BALANCE_CURRENCY_SYMBOL) public balanceCurrency;
  public appId = 730;
  public step = 1;
  public openURL = openURL;
  public selectedPaymentSystem: PaymentSystem | null = null;
  public selectedSteamItems: any[] = [];
  tradeUrl = "";
  offer: string | null = null;
  partnerRedirectUrl: string | null = null;

  get steamIdFromTradeUrl() {
    return this.tradeUrl
      ? SteamId.fromIndividualAccountID(
          (/partner=(\d+)/.exec(this.tradeUrl) || [])[1]
        ).getSteamID64()
      : undefined;
  }

  get cookieTradeurlKey() {
    return `${this.$route.params.steamId ||
      this.$route.params.userId}_tradeUrl`;
  }

  @Watch("tradeUrl")
  onTradeUrlChanged(value) {
    if (typeof value === "string") {
      if (value) {
        this.$q.cookies.set(this.cookieTradeurlKey, this.tradeUrl, {
          expires: 7,
          path: '/'
        });
      } else {
        this.$q.cookies.remove(this.cookieTradeurlKey);
      }
    }
  }

  created() {
    const tradeUrl = this.$q.cookies.get(this.cookieTradeurlKey);

    if (tradeUrl) {
      this.tradeUrl = tradeUrl;
    }

    if (this.$route.params.steamId && this.partnerOfferPaymentData) {
      this.$router.replace({ query: {} });
    }

    if (!this.$route.params.steamId && !this.partnerOfferPaymentData) {
      this.$q
        .dialog({
          title: "Платеж не может быть создан",
          message: `Не переданы обязательные параметры для составления платежа. Вы будете перенаправлены обратно на сайт.`,
          cancel: false,
          dark: true,
          persistent: true
        })
        .onOk(() => {
          //@ts-ignore
          window.location.href = this.partnerRedirectUrl;
        });
    }

    if( typeof this.$route.query.language === "string"){
      this.$i18n.locale = this.$route.query.language
    }


  }

  mounted(){
    if( typeof this.$route.query.currency === "string"){
      this.$root.$emit("update:balanceCurrency", this.$route.query.currency);
    }
  }

  get partnerOfferPaymentData() {
    return "payment_id" in this.$route.query &&
      "min_amount" in this.$route.query &&
      "user_id" in this.$route.query &&
      "sign" in this.$route.query
      ? {
          minAmount: Number(this.$route.query.min_amount),
          sign: this.$route.query.sign,
          paymentId: this.$route.query.payment_id,
          userId: this.$route.query.user_id
        }
      : undefined;
  }

  @Watch("balanceCurrency")
  watchBalanceCurrency() {
    if (this.selectedPaymentSystem) {
      this.selectedPaymentSystem.currency = this.balanceCurrency;
    }
  }

  async setPaymentSystem({ data }) {
    if (data && data.getPartnerInfo) {
      this.partnerRedirectUrl = data.getPartnerInfo.partnerRedirectUrl;
      this.selectedPaymentSystem = new PaymentSystem(
        undefined,
        data.getPartnerInfo.name,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        this.partnerOfferPaymentData
          ? this.partnerOfferPaymentData.minAmount
          : data.getPartnerInfo.partnerMinDeposit,
        this.balanceCurrency
      );
      await this.$nextTick();
      setTimeout(() => {
        this.animate();
      }, 300);
    }
  }

  get offerPrice() {
    return this.selectedSteamItems
      ? Number(
          _.sum(
            this.selectedSteamItems.map(steamItem => steamItem.price)
          ).toFixed(2)
        )
      : 0;
  }

  createOfferDone(offer) {
    this.offer = offer;
  }

  steamTradeOfferDialogClose(success) {
    this.offer = null;

    if (success && (this.partnerRedirectUrl || this.$route.query.success_url)) {
      window.location.href = (this.$route.query.success_url ||
        this.partnerRedirectUrl) as string;
    } else if (success === false && this.$route.query.fail_url) {
      window.location.href = this.$route.query.fail_url as string;
    }
  }

  animate() {
    if (!this.$q.platform.is.mobile) {
      //gsap.to(`#desktop-main`, { marginTop: -100, duration: 0.5 });
    }
  }
}
